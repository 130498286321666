import React from 'react';

const order = () => {
  return (
    <div>
      <h1>Hello from Order Page</h1>
    </div>
  );
};

export default order;
